import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ListItem, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	item: {
		display: "block",
		paddingTop: 0,
		paddingBottom: 0,
	},
	itemLeaf: {
		display: "flex",
		paddingTop: 0,
		paddingBottom: 0,
	},
	button: {
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
		color: "gray",
		fontWeight: "bold",
	},
	buttonLeaf: {
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
		"&.depth-0": {
			fontWeight: "bold",
		},
		color: "gray",
		fontWeight: "bold",
	},
	label: {
		display: "flex",
		alignItems: "center",
		marginLeft: "auto",
	},
}));

function NavItem({
	title,
	href,
	depth,
	className,
	open: openProp,
	label: Label,
	...rest
}) {
	const classes = useStyles();

	let paddingLeft = 8;

	if (depth > 0) {
		paddingLeft = 32 + 8 * depth;
	}

	const style = {
		paddingLeft,
	};

	return (
		<ListItem
			{...rest}
			className={clsx(classes.itemLeaf, className)}
			disableGutters
			key={title}
		>
			<Button
				activeClassName={classes.active}
				className={clsx(classes.buttonLeaf, `depth-${depth}`)}
				component={RouterLink}
				exact
				style={style}
				to={href}
			>
				{title}
				{Label && (
					<span className={classes.label}>
						<Label />
					</span>
				)}
			</Button>
		</ListItem>
	);
}

NavItem.propTypes = {
	className: PropTypes.string,
	depth: PropTypes.number.isRequired,
	href: PropTypes.string,
	icon: PropTypes.any,
	label: PropTypes.any,
	open: PropTypes.bool,
	title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
	open: false,
};

export default NavItem;
