import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Container,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
	root: {
		paddingTop: "30px",
		paddingBottom: "30px",
	},
	content: {
		paddingTop: "30px",
	},
	formValue: {
		marginTop: "10px",
	},
}));

const Home = () => {
	const classes = useStyles();
	const history = useHistory();

	const redirectToSMS = () => {
		history.push("/sms");
	};

	const redirectToElektrolyse = () => {
		history.push("/elektrolyse");
	};

	const redirectToForhandler = () => {
		history.push("/forhandler");
	};

	return (
		<Container className={classes.content} maxWidth="lg">
			<Card>
				<CardHeader title="Aktivering af Sim kort" />
				<CardContent className={classes.content}>
					<Grid container spacing={1}>
						<Grid item xs>
							<Button
								style={{
									backgroundColor: "#01579b",
									color: "white",
								}}
								onClick={redirectToSMS}
								variant="contained"
							>
								SMS Boks
							</Button>
						</Grid>
						<Grid item xs>
							<Button
								style={{
									align: "center",
									backgroundColor: "#01579b",
									color: "white",
								}}
								onClick={redirectToElektrolyse}
								variant="contained"
							>
								Elektrolyse
							</Button>
						</Grid>
						<Grid item xs>
							<Button
								style={{
									align: "center",
									backgroundColor: "#01579b",
									color: "white",
								}}
								onClick={redirectToForhandler}
								variant="contained"
							>
								Forhandler
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Container>
	);
};

export default Home;
