import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Snackbar,
	IconButton,
	Container,
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import Modal from "../../components/UI/Modal/Modal";
import validate from "validate.js";
import axios from "axios";
import { useLocation } from "react-router-dom";
import blue from "@material-ui/core/colors/blue";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: "30px",
		paddingBottom: "30px",
	},
	content: {
		paddingTop: "30px",
	},
	formValue: {
		marginTop: "10px",
	},
	input: {
		display: "none",
	},
	button: {
		color: blue[900],
		height: "45px",
		width: "45px",
	},
}));

const initialState = {
	isValid: false,
	values: {},
	touched: {},
	errors: {},
};

const schema = {
	ServiceObjectID: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			message: "Kan kun indeholde numre",
		},
		length: {
			minimum: 11,
			maximum: 14,
			message: "skal enten være 11 eller 14 numre",
		},
	},
	IMSI: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			message: "Kan kun indeholde numre",
		},
		length: {
			is: 15,
			message: "skal være 15 numre langt",
		},
	},
	Email: {
		presence: { allowEmpty: false, message: "is required" },
		email: true,
	},
	FilterType: {
		presence: { allowEmpty: false, message: "is required" },
		inclusion: {
			within: { 93: "AK", 63: "BESTMAX", 82: "SALTALARM", 71: "ELEKTROLYSE" },
			message: "^We're currently out of %{value}",
		},
	},
	GarantiDato: {
		presence: { allowEmpty: false, message: "is required" },
		inclusion: {
			within: {
				12: "12 Måneder",
				11: "11 Måneder",
				10: "10 Måneder",
				9: "9 Måneder",
				8: "8 Måneder",
				7: "7 Måneder",
				6: "6 Måneder",
				5: "5 Måneder",
				4: "4 Måneder",
				3: "3 Måneder",
				2: "2 Måneder",
				1: "1 Måneder",
			},
			message: "skal være mellem 1 og 12 måneder",
		},
	},
};

const SMS = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);
	const [installationsModalOpen, setInstallationModalOpen] = useState(false);
	const [IMSIModalOpen, setIMSIModalOpen] = useState(false);
	const location = useLocation();

	useEffect(() => {
		let { search } = location;
		let imsi, id, email, type, dato;
		imsi = id = email = type = dato = null;
		if (search) {
			search = search.substr(1);

			let searchJSON = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

			if ("id" in searchJSON) {
				id = searchJSON["id"];
			}
			if ("imsi" in searchJSON) {
				imsi = searchJSON["imsi"];
			}
			if ("email" in searchJSON) {
				email = searchJSON["email"];
			}
			if ("type" in searchJSON) {
				type = searchJSON["type"];
			}
			if ("dato" in searchJSON) {
				dato = searchJSON["dato"];
			}

			if (imsi || type || id || email || dato) {
				setFormState((prevFormState) => ({
					...prevFormState,
					values: {
						...prevFormState.value,
						IMSI: imsi,
						Email: email,
						ServiceObjectID: id,
						GarantiDato: dato,
						FilterType: type,
					},
					touched: {
						...prevFormState.touched,
						IMSI: imsi ? true : false,
						Email: email ? true : false,
						ServiceObjectID: id ? true : false,
						GarantiDato: dato ? true : false,
						FilterType: type ? true : false,
					},
				}));
			}
		}
	}, []);

	// Change handler for input changed
	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	// Check if input has error
	const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const onActivateSIM = async () => {
		setLoading(true);

		console.log(formState.values);

		axios
			.post("https://api.bwt.dk/api/routes/activatesim.create.php", formState.values)
			.then((response) => {
				handleSnackbarClick(response.data.message, "success");
				setFormState(initialState);
			})
			.catch((error) => {
				if (error.response) {
					handleSnackbarClick(error.response.data.message, "error");
				} else {
					handleSnackbarClick("Der skete en fejl.", "error");
				}
			});

		setLoading(false);
	};

	const handleModalClose = () => {
		setInstallationModalOpen(false);
	};

	const handleModalOpen = () => {
		setInstallationModalOpen(true);
	};

	const handleModalCloseIMSI = () => {
		setIMSIModalOpen(false);
	};

	const handleModalOpenIMSI = () => {
		setIMSIModalOpen(true);
	};

	return (
		<Container className={classes.content} maxWidth="lg">
			<Card>
				<CardHeader title="Aktivering af SMS Sim kort på installation" />
				<CardContent className={classes.content}>
					<Grid container spacing={1} alignItems="flex-end">
						<Grid item>
							<InfoIcon onClick={handleModalOpen} />
						</Grid>
						<Grid item xs>
							<TextField
								fullWidth
								label="Kunde og installationsnummer (ServiceObjectID)"
								name="ServiceObjectID"
								error={hasError("ServiceObjectID")}
								helperText={hasError("ServiceObjectID") ? formState.errors.ServiceObjectID[0] : null}
								value={formState.values.ServiceObjectID || ""}
								onChange={handleChange}
							/>
						</Grid>
						<Modal
							open={installationsModalOpen}
							handleClose={handleModalClose}
							title={"ServiceObjectId"}
							description={"ServiceObjectId eller Serviceenhed er en kombination af kunde og installationsnummer"}
							image={"sm.jpg"}
						/>
					</Grid>
					<Grid container spacing={1} alignItems="flex-end">
						<Grid item>
							<InfoIcon onClick={handleModalOpenIMSI} />
						</Grid>
						<Grid item xs>
							<Grid container spacing={1}>
								<Grid item xs>
									<TextField
										id="imsin"
										fullWidth
										label="IMSI eller MSISDN"
										name="IMSI"
										error={hasError("IMSI")}
										helperText={hasError("IMSI") ? formState.errors.IMSI[0] : null}
										value={formState.values.IMSI || ""}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Modal
							open={IMSIModalOpen}
							handleClose={handleModalCloseIMSI}
							title={"IMSI"}
							description={"IMSI-nummeret kan findes på dit SIM-kort"}
							image={"IMG_2838.jpg"}
						/>
					</Grid>
					<Grid className={classes.formValue}>
						<TextField
							fullWidth
							label="Email"
							name="Email"
							error={hasError("Email")}
							helperText={hasError("Email") ? formState.errors.Email[0] : "Din email til bekræftelse af aktivering"}
							onChange={handleChange}
							value={formState.values.Email || ""}
						/>
					</Grid>
					<Grid className={classes.formValue}>
						<FormControl fullWidth>
							<InputLabel id="filtertype">Filter Type</InputLabel>
							<Select labelId="filtertype" value={formState.values.FilterType || ""} onChange={handleChange} name="FilterType" label="Filter Type">
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								<MenuItem value="93">AK eller BESTSHINE</MenuItem>
								<MenuItem value="63">BESTMAX</MenuItem>
								<MenuItem value="82">SALTALARM</MenuItem>
								<MenuItem value="71">ELEKTROLYSE</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid className={classes.formValue}>
						<FormControl fullWidth>
							<InputLabel id="garantidato">Garanti Dato / Sikkerhedsinterval</InputLabel>
							<Select
								labelId="garantidato"
								value={formState.values.GarantiDato || ""}
								onChange={handleChange}
								name="GarantiDato"
								label="Garanti Dato / Sikkerhedsinterval"
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								<MenuItem value="12">12 måneder</MenuItem>
								<MenuItem value="11">11 måneder</MenuItem>
								<MenuItem value="10">10 måneder</MenuItem>
								<MenuItem value="9">9 måneder</MenuItem>
								<MenuItem value="8">8 måneder</MenuItem>
								<MenuItem value="8">8 måneder</MenuItem>
								<MenuItem value="7">7 måneder</MenuItem>
								<MenuItem value="6">6 måneder</MenuItem>
								<MenuItem value="5">5 måneder</MenuItem>
								<MenuItem value="4">4 måneder</MenuItem>
								<MenuItem value="3">3 måneder</MenuItem>
								<MenuItem value="2">2 måneder</MenuItem>
								<MenuItem value="1">1 måneder</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid className={classes.formValue}>
						{loading ? (
							<CircularProgress />
						) : (
							<Button disabled={!formState.isValid} onClick={onActivateSIM} variant="contained" color="primary">
								Aktiver
							</Button>
						)}
					</Grid>
					<Snackbar
						anchorOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						severity={snackbarSeverity}
						open={snackbarOpen}
						autoHideDuration={18000}
						onClose={handleSnackbarClose}
						message={<span id="message-id">{snackbarMessage}</span>}
						action={[
							<IconButton key="close" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
								<CloseIcon />
							</IconButton>,
						]}
					/>
				</CardContent>
			</Card>
		</Container>
	);
};

export default SMS;
