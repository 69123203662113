import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {
	AppBar,
	Toolbar,
	Typography,
	Container,
	Hidden,
	Drawer,
	IconButton,
	Divider,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NavItem from "../../NavItem";
import Logo from "../../../img/logo.png";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	drawerRoot: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		marginTop: "30px",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	link: {
		textDecoration: "none",
		color: "white",
		padding: "7px 15px",
		margin: "0 5px",
		border: "1px solid black",
		borderRadius: "3px",
	},
	button: {
		margin: "0 5px",
	},
	active: {
		backgroundColor: "#078cf5",
	},
	appBar: {
		backgroundColor: "#01579b",
	},
	mobileDrawer: {
		width: 256,
	},
	navigation: {
		overflow: "auto",
		padding: theme.spacing(0, 2, 2, 2),
		flexGrow: 1,
	},
	logo: {
		maxHeight: "40px",
		flexGrow: 1,
	},
}));

function Header() {
	const classes = useStyles();
	const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setOpenNavBarMobile(false);
	}, [location.pathname]);

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<Container>
					<Toolbar>
						<Typography variant="h6" className={classes.title}>
							<NavLink to="/" exact>
								<img
									className={classes.logo}
									alt="Logo"
									src={Logo}
								/>
							</NavLink>
						</Typography>
						<Hidden mdDown>
							<NavLink
								activeClassName={classes.active}
								className={classes.link}
								to="/sms"
								exact
							>
								SMS
							</NavLink>
						</Hidden>
						<Hidden mdDown>
							<NavLink
								className={classes.link}
								to="/elektrolyse"
								exact
								activeClassName={classes.active}
							>
								Elektrolyse
							</NavLink>
						</Hidden>
						<Hidden mdDown>
							<NavLink
								className={classes.link}
								to="/forhandler"
								exact
								activeClassName={classes.active}
							>
								Forhandler
							</NavLink>
						</Hidden>
						<Hidden lgUp>
							<IconButton
								className={classes.menuButton}
								color="inherit"
								onClick={() => setOpenNavBarMobile(true)}
							>
								<MenuIcon />
							</IconButton>
						</Hidden>
					</Toolbar>
				</Container>
			</AppBar>
			<Hidden lgUp>
				<Drawer
					anchor="right"
					classes={{
						paper: classes.mobileDrawer,
					}}
					onClose={() => setOpenNavBarMobile(false)}
					open={openNavBarMobile}
					variant="temporary"
				>
					<div className={classes.drawerRoot}>
						<nav className={classes.navigation}>
							<NavItem depth={0} href={"/sms"} title="SMS" />
							<Divider />
							<NavItem
								depth={0}
								href={"/elektrolyse"}
								title="Elektrolyse"
							/>
							<Divider />
							<NavItem
								depth={0}
								href={"/forhandler"}
								title="Forhandler"
							/>
						</nav>
					</div>
				</Drawer>
			</Hidden>
		</>
	);
}

export default Header;
