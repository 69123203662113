import React, { Component } from "react";

import Aux from "../Auxi/Auxi";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";

class Layout extends Component {
	render() {
		return (
			<Aux>
				<Toolbar />
				<div>
					<main>{this.props.children}</main>
				</div>
			</Aux>
		);
	}
}
export default Layout;
