import React from "react";
import { Route, Switch } from "react-router-dom";

import Layout from "./hoc/Layout/Layout";

import SMS from "./views/SMS";
import Elektrolyse from "./views/Elektrolyse";
import Forhandler from "./views/Forhandler";
import Home from "./views/Home";

import "./App.css";

const app = () => {
	return (
		<div>
			<Layout>
				<Switch>
					<Route path="/elektrolyse" component={Elektrolyse} />
					<Route path="/sms" component={SMS} />
					<Route path="/forhandler" component={Forhandler} />
					<Route path="/" exact component={Home} />
				</Switch>
			</Layout>
		</div>
	);
};
export default app;
