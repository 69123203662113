import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Snackbar,
	IconButton,
	Container,
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Button,
	CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import validate from "validate.js";
import axios from "axios";
import Modal from "../../components/UI/Modal/Modal";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: "30px",
		paddingBottom: "30px",
	},
	content: {
		paddingTop: "30px",
	},
	formValue: {
		marginTop: "10px",
	},
	formButton: {
		marginTop: "30px",
	},
}));

const initialState = {
	isValid: false,
	values: {},
	touched: {},
	errors: {},
};

const schema = {
	kundenummer: {
		presence: { allowEmpty: false, message: "kan ikke være tomt" },
		length: {
			minimum: 5,
			maximum: 8,
			message: "skal være mellem 6 og 8 numre",
		},
		numericality: {
			onlyInteger: true,
			message: "kan kun indeholde numre",
		},
	},
	installationsnummer: {
		presence: { allowEmpty: false, message: "kan ikke være tomt" },
		length: {
			minimum: 1,
			message: "skal minimum være et nummer langt",
		},
		numericality: {
			onlyInteger: true,
			message: "kan kun indeholde numre",
		},
	},
	simkort: {
		presence: { allowEmpty: false, message: "kan ikke være tomt" },
		length: {
			is: 20,
			message: "skal være 20 numre langt",
		},
		numericality: {
			onlyInteger: true,
			message: "kan kun indeholde numre",
		},
	},
	email: {
		presence: { allowEmpty: false, message: "kan ikke være tomt" },
		email: true,
	},
};

const Forhandler = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);
	const [simModal, setSimModal] = useState(false);

	// Change handler for input changed
	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	// Check if input has error
	const hasError = (field) =>
		!!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const onActivateSIM = async () => {
		setLoading(true);

		console.log(formState.values);

		axios
			.post(
				"https://api.bwt.dk/api/routes/activateForhandler.php",
				formState.values
			)
			.then((response) => {
				handleSnackbarClick(response.data.message, "success");
				setFormState(initialState);
			})
			.catch((error) => {
				if (error.response) {
					handleSnackbarClick(error.response.data.message, "error");
				} else {
					handleSnackbarClick("Der skete en fejl.", "error");
				}
			});

		setLoading(false);
	};

	const handleModalClose = () => {
		setSimModal(false);
	};

	const handleModalOpen = () => {
		setSimModal(true);
	};

	return (
		<Container className={classes.content} maxWidth="lg">
			<Card>
				<CardHeader title="Aktivering af sms kort til forhandlere" />
				<CardContent className={classes.content}>
					<Grid container spacing={3} className={classes.formValue}>
						<Grid item xs>
							<TextField
								fullWidth
								label="Kundenummer"
								name="kundenummer"
								error={hasError("kundenummer")}
								helperText={
									hasError("kundenummer")
										? formState.errors.kundenummer[0]
										: null
								}
								value={formState.values.kundenummer || ""}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs>
							<TextField
								fullWidth
								label="Installationsnummer"
								name="installationsnummer"
								error={hasError("installationsnummer")}
								helperText={
									hasError("installationsnummer")
										? formState.errors.installationsnummer[0]
										: null
								}
								value={formState.values.installationsnummer || ""}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1} className={classes.formValue}>
						<Grid item xs>
							<TextField
								fullWidth
								label="Email"
								name="email"
								type="email"
								error={hasError("email")}
								helperText={
									hasError("email")
										? formState.errors.email[0]
										: "Din email til bekræftelse af aktivering"
								}
								onChange={handleChange}
								value={formState.values.email || ""}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={3}
						className={classes.formValue}
						alignItems="flex-end"
					>
						<Grid item>
							<InfoIcon onClick={handleModalOpen} />
						</Grid>
						<Grid item xs>
							<TextField
								fullWidth
								label="Simkort nummer"
								name="simkort"
								error={hasError("simkort")}
								helperText={
									hasError("simkort") ? formState.errors.simkort[0] : null
								}
								onChange={handleChange}
								value={formState.values.simkort || ""}
							/>
						</Grid>
						<Modal
							open={simModal}
							handleClose={handleModalClose}
							title={"Sim kort nummer"}
							description={
								"Sim kort nummeret kan findes på sim kortet i får udleveret under icc eller på selve nano sim kortet."
							}
							image={"css.png"}
						/>
					</Grid>

					<Grid className={classes.formButton}>
						{loading ? (
							<CircularProgress />
						) : (
							<Button
								disabled={!formState.isValid}
								onClick={onActivateSIM}
								variant="contained"
								color="primary"
							>
								Send Aktivering
							</Button>
						)}
					</Grid>
					<Snackbar
						anchorOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						severity={snackbarSeverity}
						open={snackbarOpen}
						autoHideDuration={18000}
						onClose={handleSnackbarClose}
						message={<span id="message-id">{snackbarMessage}</span>}
						action={[
							<IconButton
								key="close"
								aria-label="close"
								color="inherit"
								onClick={handleSnackbarClose}
							>
								<CloseIcon />
							</IconButton>,
						]}
					/>
				</CardContent>
			</Card>
		</Container>
	);
};

export default Forhandler;
